enum QUERY_KEYS {
  GET_MOCK_DATA = "GET_MOCK_DATA",
  GET_MODAL_DATA = "GET_MODAL_DATA",
  GET_LISTING_DATA = "GET_LISTING_DATA",
  GET_DATA_UPLOAD = "GET_DATA_UPLOAD",
  GET_HOME_DATA_UPLOADED = "GET_HOME_DATA_UPLOADED",
  GET_HOME_DATA_RESULTAT = "GET_HOME_DATA_RESULTAT",
  AUTOCOMPLETE_SEARCH = "AUTOCOMPLETE_SEARCH",
  GET_GEO_MAP = "GET_GEO_MAP",
  GET_DATA_FIABILITE = "GET_DATA_FIABILITE",
  GET_RE_CALCULATE_RESULT = "GET_RE_CALCULATE_RESULT",
  GET_CONTROLS_RESULT = "GET_CONTROLS_RESULT",
  GET_SUGGESTION_RENOVATION = "GET_SUGGESTION_RENOVATION",
  GET_GRAPHE_PRICE = "GET_GRAPHE_PRICE",
}

export default QUERY_KEYS;
