import { useMemo } from "react";
import dayjs from "dayjs";
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Badge } from "antd";

import {
  ColDef,
  // ModuleRegistry,
  // ClientSideRowModelModule,
  CellClickedEvent,
} from "ag-grid-community";

import { getDashboardURL } from "routes/constants";
import QUERY_KEYS from "services/api/queryKeys";
import API from "services/api";
import { MAP_COLOR_DPE } from "constants/index";
import "styles/list.scss";

// ModuleRegistry.registerModules([ClientSideRowModelModule]);

function ListPage() {
  const navigate = useNavigate();

  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_LISTING_DATA],
    queryFn: API.mock.getListingData,
  });

  const handleClickCell = (event: CellClickedEvent) => {
    console.log("handleClickCell", event);
    navigate(getDashboardURL(event.data.id));
  };

  const rowData = useMemo(() => {
    return data?.map((item: any) => ({
      ...item,
      status: Boolean(item.status),
    }));
  }, [data]);

  const colDefs: ColDef[] = useMemo(
    () => [
      {
        field: "address_label",
        headerName: "Address",
        type: "longText",
      },
      {
        field: "dpe",
        headerName: "DPE",
        type: "shortText",
        cellRenderer: (item: any) => {
          return (
            <Badge
              count={item.value}
              color={MAP_COLOR_DPE[item.value]}
              style={{ minWidth: "40px" }}
            />
          );
        },
      },
      {
        field: "name",
        // type: "mediumText",
      },
      {
        field: "date",
        // type: "mediumText",
        valueFormatter: (item) =>
          dayjs.unix(item.value).local().format("YYYY-MM-DD HH:mm:ss"),
        // valueGetter: (col: any) =>
        //   dayjs.unix(col.data.date).local().format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        field: "status",
        // type: "shortText",
      },
    ],
    []
  );

  return (
    <div className="energy-list">
      <div className="ag-theme-quartz">
        <AgGridReact
          loadingCellRenderer={isLoading}
          rowData={rowData}
          columnDefs={colDefs}
          columnTypes={{
            longText: {
              width: 400,
            },
            mediumText: {
              width: 200,
            },
            shortText: {
              width: 100,
            },
          }}
          onCellClicked={handleClickCell}
        />
      </div>
    </div>
  );
}

export default ListPage;
