import _ from "lodash";
import { FC, useEffect, useState } from "react";
import { Card, Col, Row, Tabs, TabsProps } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import classNames from "classnames";

import { ReactComponent as IconPencil } from "assets/images/pencil.svg";
import { ReactComponent as IconWall } from "assets/images/renovation/wall.svg";
import { ReactComponent as IconAir } from "assets/images/renovation/air-conditioner.svg";
import { ReactComponent as IconDoor } from "assets/images/renovation/door.svg";
import { ReactComponent as IconHouse } from "assets/images/renovation/house.svg";
import { ReactComponent as IconWater } from "assets/images/renovation/water.svg";
import { ReactComponent as IconRise } from "assets/images/renovation/rise.svg";
import { ReactComponent as IconEuro } from "assets/images/renovation/euro.svg";
import { MAP_COLOR_DPE } from "constants/index";

import "styles/dashboard.scss";

interface Props {
  dataSuggestion: any;
}

const MAP_ICON: any = {
  wall: IconWall,
  ceiling: IconHouse,
  heating: IconAir,
  doorswindows: IconDoor,
  hotwater: IconWater,
};

const SuggestionRenovation: FC<Props> = ({ dataSuggestion }) => {
  const [tab, setTab] = useState("");

  useEffect(() => {
    if (!_.isEmpty(dataSuggestion)) {
      const activeTab = dataSuggestion.find((item: any) => item.recommended);
      setTab(activeTab.iter);
    }
  }, [dataSuggestion]);

  const tabMapItems: TabsProps["items"] = dataSuggestion.map(
    (tabContent: any) => ({
      key: tabContent.iter,
      label: `Solution ${tabContent.iter}${
        tabContent.recommended ? " (recommandée)" : ""
      }`,
      children: (
        <Row gutter={[24, 24]}>
          <Col
            span={24}
            order={2}
            lg={{
              span: 16,
              order: 1,
            }}
          >
            <h4 className="font-bold text-[32px] mb-6">
              Solution {tabContent.iter}
              {tabContent.recommended ? " recommandée par ledpe" : ""}
            </h4>

            <div className="flex flex-col gap-y-6">
              {!_.isEmpty(tabContent.projects) &&
                tabContent.projects.map((item: any, index: number) => {
                  const Icon = MAP_ICON[item.object];
                  return (
                    <div
                      key={index}
                      className={classNames("suggestion-item", {
                        active: item.hightlight,
                      })}
                    >
                      <div className="flex items-center justify-between flex-wrap gap-y-2">
                        <p className="flex items-center gap-x-2.5 text-[20px] font-bold">
                          <Icon />
                          <span>{item.title}</span>
                        </p>

                        <div className="flex items-center gap-x-6">
                          <div className="flex items-center">
                            <span className="font-semibold text-[16px] mr-1">
                              Gain
                            </span>
                            {[...new Array(4)].map((__, index) => (
                              <IconRise
                                key={index}
                                className={classNames("icon-stat", {
                                  "icon-stat--active": item.gain >= index + 1,
                                })}
                              />
                            ))}
                          </div>

                          <div className="flex items-center">
                            <span className="font-semibold text-[16px] mr-1">
                              Prix
                            </span>
                            {[...new Array(4)].map((__, index) => (
                              <IconEuro
                                key={index}
                                className={classNames("icon-stat", {
                                  "icon-stat--active": item.cost >= index + 1,
                                })}
                              />
                            ))}
                          </div>
                        </div>
                      </div>

                      {!_.isEmpty(item.description) &&
                        item.description.map((desc: string, index: number) => (
                          <p className="text-[20px] mt-1" key={index}>
                            {desc}
                          </p>
                        ))}
                    </div>
                  );
                })}
            </div>
          </Col>
          <Col
            span={24}
            order={1}
            lg={{
              span: 8,
              order: 2,
            }}
          >
            <div className="energy-home__montant">
              <p className="flex items-center font-bold text-[24px] gap-x-1">
                <IconEuro />
                Montant des travaux
              </p>
              <p className="text-[20px] pl-7">
                Entre {tabContent.estimated_cost[0]}€ et{" "}
                {tabContent.estimated_cost[1]}€
              </p>

              <p className="flex items-center font-bold text-[24px] gap-x-1 mt-4">
                <IconRise />
                Passez de la lettre
              </p>

              <div className="flex items-center gap-x-[20px] pl-7 mt-2">
                <div
                  className="montant-label"
                  style={{
                    background: MAP_COLOR_DPE[_.get(tabContent, "gain.0", "")],
                  }}
                >
                  {_.get(tabContent, "gain.0", "")}
                </div>
                <span className="text-[32px]" style={{ color: "#414141" }}>
                  à
                </span>
                <div
                  className="montant-label"
                  style={{
                    background: MAP_COLOR_DPE[_.get(tabContent, "gain.1", "")],
                  }}
                >
                  {_.get(tabContent, "gain.1", "")}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      ),
    })
  );

  return (
    <Card className="mb-3">
      <div className="energy-home__suggestion-renovation">
        <div className="flex items-center justify-between mb-3">
          <h3 className="flex items-center gap-x-2.5 font-bold text-[40px] leading-[48px]">
            <IconPencil />
            <span>Vos scénarios travaux</span>
          </h3>

          <InfoCircleOutlined style={{ fontSize: 24 }} />
        </div>

        <div className="energy-home__suggestion-renovation__tabs">
          <Tabs
            centered
            activeKey={tab}
            items={tabMapItems}
            onChange={setTab}
          />
        </div>
      </div>
    </Card>
  );
};

export default SuggestionRenovation;
