import _ from "lodash";
import dayjs from "dayjs";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ColDef, CellClickedEvent, RowSelectedEvent } from "ag-grid-community";
import {
  Radio,
  Checkbox,
  Skeleton,
  message,
  Badge,
  Dropdown,
  Input,
  Button,
} from "antd";
import type { MenuProps, RadioChangeEvent } from "antd";

import QUERY_KEYS from "services/api/queryKeys";
import { getDashboardURL } from "routes/constants";
import API from "services/api";
import { CONTROL_BUTTON, MAP_COLOR_DPE } from "constants/index";

import "styles/control.scss";

const MAP_COLOR_TABLE: Record<number, string> = {
  0: "#fff",
  1: "#6EB86E20",
  2: "#FEB87020",
  3: "#B4231820",
};

function CSTBPage() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const gridApi = useRef<any>();
  const [messageApi, contextHolder] = message.useMessage();
  const [hashQuery, setHashQuery] = useQueryParam(
    "hash",
    withDefault(StringParam, "all")
  );

  const [selectedNodeIds, setSelectedNodeIds] = useState<(number | null)[]>([]);
  const [hashs, setHashs] = useState([]);
  const [hashInput, setHashInput] = useState("");

  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_CONTROLS_RESULT, hashQuery],
    queryFn: () => API.mock.getControlsResult(hashQuery, "cstb"),
  });

  const { mutateAsync: mutatePostRowControl, isPending } = useMutation({
    mutationFn: (params: any) => API.mock.postSelectedRowControl(params),
  });

  const { mutateAsync: mutateSendDataControl, isPending: isSending } =
    useMutation({
      mutationFn: ({ controlName, params }: any) =>
        API.mock.sendDataControl(controlName, params),
    });

  useEffect(() => {
    if (_.isEmpty(hashs) && !_.isEmpty(data)) {
      setHashs(data?.hashs || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, hashs]);

  const onChange = (e: RadioChangeEvent) => {
    // setControlFilter(e.target.value);
    setHashQuery(e.target.value);
  };

  const handleClickCell = (event: CellClickedEvent) => {
    if (event.colDef.colId === "ag-Grid-ControlsColumn") {
      return;
    }
    navigate(getDashboardURL(event.data.id));
  };

  const handleChangeSelectedRow = (event: RowSelectedEvent) => {
    const nodeIds = event.api.getSelectedNodes().map((item) => item?.data?.id);
    setSelectedNodeIds(nodeIds);
  };

  const handleSendSelectedRow = async () => {
    try {
      await mutatePostRowControl({
        selected: selectedNodeIds,
      });

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_CONTROLS_RESULT, hashQuery],
      });

      gridApi.current?.api?.deselectAll();

      setSelectedNodeIds([]);
      messageApi.open({
        type: "success",
        content: "Success",
      });
    } catch (e) {
      messageApi.open({
        type: "error",
        content: "Failed",
      });
    }
  };

  const onMenuClick: MenuProps["onClick"] = (e) => {
    handleSendDataControl(e.key);
  };

  const handleSendDataControl = async (controlName: string) => {
    try {
      const payload = {
        controlName: controlName,
        params: {
          _id: selectedNodeIds,
        },
      } as any;

      if (hashInput) {
        payload.params.hash_input = hashInput;
      }

      if (hashQuery && hashQuery !== "all") {
        payload.params.selected_hash = hashQuery;
      }

      await mutateSendDataControl(payload);

      setHashInput("");
      setSelectedNodeIds([]);

      gridApi.current?.api?.deselectAll();

      messageApi.open({
        type: "success",
        content: "Success",
      });
    } catch (e) {
      messageApi.open({
        type: "error",
        content: "Failed",
      });
    }
  };

  const items = [
    {
      key: CONTROL_BUTTON.BATCH,
      label: "Batch",
    },
    {
      key: CONTROL_BUTTON.REINIT,
      label: "Re-init",
    },
    {
      key: CONTROL_BUTTON.DEPLOY,
      label: "Deploy",
    },
  ];

  const rowData = useMemo(() => {
    return data?.table_values;
  }, [data]);

  // Column Definitions: Defines & controls grid columns.
  const colTypes = useMemo(() => {
    return {
      longText: {
        width: 400,
      },
      mediumText: {
        width: 200,
      },
      shortText: {
        width: 100,
      },
    };
  }, []);

  const colDefs: ColDef[] = useMemo(() => {
    if (_.isEmpty(data)) {
      return [];
    }

    const columns = data?.table_values[0]
      ? Object.keys(data?.table_values[0]).map((key, index) => {
          if (key === "status") {
            return {
              field: key,
              headerName: key,
              type: "shortText",
              cellRenderer: (item: any) => {
                return <Checkbox checked={!!item.data?.status} />;
              },
            };
          }

          if (key === "dpe") {
            return {
              field: key,
              headerName: key,
              type: "shortText",
              cellRenderer: (item: any) => {
                return (
                  <Badge
                    count={item.value}
                    color={MAP_COLOR_DPE[item.value]}
                    style={{ minWidth: "40px" }}
                  />
                );
              },
            };
          }

          return {
            field: key,
            headerName: key,
            type: "mediumText",
            cellStyle: (params: any) => {
              if (!["id", "hash"].includes(String(params.colDef.colId))) {
                return {
                  backgroundColor: MAP_COLOR_TABLE[params.value] || "#fff",
                };
              }
              return null;
            },
          };
        })
      : [];

    return columns;
  }, [data]);

  return (
    <div className="energy-control">
      <h4>
        {isLoading ? (
          <Skeleton.Input style={{ height: 30 }} active />
        ) : (
          data?.title || ""
        )}
      </h4>
      <div className="mb-4">
        {isLoading ? (
          <Skeleton.Input style={{ height: 20 }} active />
        ) : (
          data?.subtitle || ""
        )}
      </div>

      <div className="flex items-center justify-between">
        <Radio.Group onChange={onChange} value={hashQuery}>
          <Radio.Button value="all">All</Radio.Button>

          {!_.isEmpty(hashs) &&
            hashs.map((item: any, index: number) => (
              <div
                className="ant-radio-button flex flex-col items-center"
                key={index}
              >
                <Radio.Button value={item.hash}>{item.hash}</Radio.Button>
                <p
                  style={{ color: "#ADADAD" }}
                  className="font-medium text-[13px] mt-0.5"
                >
                  {dayjs.unix(item.timestamp).format("DD/MM HH:mm")}
                </p>
              </div>
            ))}
        </Radio.Group>

        <div className="flex items-center gap-2">
          <Button
            disabled={_.isEmpty(selectedNodeIds)}
            type="primary"
            loading={isPending}
            onClick={handleSendSelectedRow}
            className="mr-4"
          >
            envoi
          </Button>

          <Input
            value={hashInput}
            onChange={(e) => setHashInput(e.target.value)}
          />

          <Dropdown.Button
            className="max-w-max"
            menu={{ items, onClick: onMenuClick }}
            onClick={() => handleSendDataControl(CONTROL_BUTTON.CONTROL)}
            loading={isSending}
          >
            Control
          </Dropdown.Button>
        </div>
      </div>
      <div className="ag-theme-quartz mt-4">
        <AgGridReact
          ref={gridApi}
          loadingCellRenderer={isLoading}
          rowData={rowData}
          columnDefs={colDefs}
          columnTypes={colTypes}
          suppressRowHoverHighlight={true}
          onCellClicked={handleClickCell}
          onRowSelected={handleChangeSelectedRow}
          rowSelection={{ mode: "multiRow" }}
        />
      </div>
      {contextHolder}
    </div>
  );
}

export default CSTBPage;
