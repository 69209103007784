import { ReactNode } from "react";
import { Form, Select } from "antd";
import { NamePath } from "antd/es/form/interface";
import { typeBienOptions } from "constants/form";

function SelectTypeBien({
  name,
  label,
  onChange,
}: {
  name: NamePath;
  label: string | ReactNode;
  onChange?: (value: string) => void;
}) {
  //@ts-ignore
  return (
    <Form.Item label={label} name={name}>
      <Select options={typeBienOptions} onChange={onChange}></Select>
    </Form.Item>
  );
}

export default SelectTypeBien;
