import _ from "lodash";
import { Input, InputNumber, Checkbox, Form } from "antd";
import SelectExposition from "components/form/enveloppe/select-exposition";
import {
  MATERIAL_RADIO_OPTIONS,
  SetOfFields,
  TYPE_FIELD,
  expositionOptions,
} from "constants/form";
import { MapValue, MappingMeasureUnit } from "constants/index";
import SelectMaterial from "./select-material";
import SelectThickness from "./select-thickness";

const ELEMENT = {
  [TYPE_FIELD.INPUT_TEXT]: Input,
  [TYPE_FIELD.INPUT_NUMBER]: InputNumber,
  [TYPE_FIELD.CHECKBOX]: Checkbox,
};

function FormElement(props: any) {
  const { renderField, type_enveloppe, isQuickView, value, material, ...rest } =
    props;

  if (renderField === "exposition") {
    if (isQuickView) {
      const optionReadMode: any = _.find(expositionOptions, {
        value,
      });

      return (
        <Form.Item label={rest.label} name={rest.name}>
          <span className="font-bold ant-value">{optionReadMode?.label}</span>
        </Form.Item>
      );
    }
    return (
      <div>
        <SelectExposition name={rest.name} label={rest.label} />
      </div>
    );
  }

  //@ts-ignore
  const Element = ELEMENT[SetOfFields[type_enveloppe][renderField]];
  if (!Element) {
    return null;
  }

  const readModeValue = !_.isNaN(Number(value))
    ? Number(value).toLocaleString()
    : MapValue[value] || value;
  const unit = MappingMeasureUnit[renderField];
  if (isQuickView) {
    if (renderField === "materiau") {
      const materialSelect = Object.keys(MATERIAL_RADIO_OPTIONS).find((key) => {
        return MATERIAL_RADIO_OPTIONS[key].find((item: any) => {
          return item.value === value;
        });
      });

      if (materialSelect) {
        return (
          <Form.Item label={rest.label} name={rest.name}>
            <span className="font-bold ant-value">
              {materialSelect} {MapValue[value] || value}
            </span>
          </Form.Item>
        );
      }
    }

    return (
      <Form.Item label={rest.label} name={rest.name}>
        <span className="font-bold ant-value">
          {!_.isNil(value) ? `${readModeValue} ${unit ? unit : ""}` : ""}
        </span>
      </Form.Item>
    );
  }

  let otherProps = {};
  if (renderField === "isolation") {
    otherProps = {
      className: "isolation",
      labelCol: {
        order: 2,
        span: 12,
        md: {
          span: 12,
        },
        sm: {
          order: 1,
        },
      },
      wrapperCol: {
        order: 1,
        span: 12,
        md: {
          span: 12,
        },
        sm: {
          order: 2,
        },
      },
    };
  }

  if (renderField === "materiau") {
    return (
      <Form.Item {...rest} {...otherProps}>
        <SelectMaterial />
      </Form.Item>
    );
  }

  if (renderField === "epaisseur") {
    return (
      <Form.Item {...rest} {...otherProps}>
        <SelectThickness material={material} />
      </Form.Item>
    );
  }

  return (
    <Form.Item {...rest} {...otherProps}>
      <Element />
    </Form.Item>
  );
}

export default FormElement;
