import _ from "lodash";
import { useMemo } from "react";
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import { useQuery } from "@tanstack/react-query";
import { ColDef } from "ag-grid-community";

import QUERY_KEYS from "services/api/queryKeys";
import API from "services/api";

import "styles/re-calcul.scss";
import { Checkbox } from "antd";

const MAP_COLOR_TABLE: Record<number, string> = {
  0: "#eeeeee",
  1: "#eeeeee",
  2: "#ddffdd",
  3: "#ddffdd",
  4: "#eeeeee",
  5: "#eeeeee",
  6: "#ffffff",
};

function ReCalCulPage() {
  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_RE_CALCULATE_RESULT],
    queryFn: API.mock.getReCalCulateResult,
  });

  const rowData = useMemo(() => {
    return data?.table_values;
  }, [data]);

  // Column Definitions: Defines & controls grid columns.
  const colTypes = useMemo(() => {
    return {
      longText: {
        width: 400,
      },
      mediumText: {
        width: 200,
      },
      shortText: {
        width: 100,
      },
    };
  }, []);

  const colDefs: ColDef[] = useMemo(() => {
    if (_.isEmpty(data)) {
      return [];
    }

    return data?.table_values[0]
      ? Object.keys(data?.table_values[0])
          .filter((key) => key !== "id")
          .map((key, index) => {
            if (key === "status") {
              return {
                field: key,
                headerName: key,
                type: "shortText",
                cellRenderer: (item: any) => {
                  return <Checkbox checked={!!item.data?.status} />;
                },
              };
            }

            return {
              field: key,
              headerName: key,
              type: key.includes("value_")
                ? "mediumText"
                : key.includes("key_")
                ? "longText"
                : "shortText",
              cellStyle: { backgroundColor: MAP_COLOR_TABLE[index] || "#fff" },
            };
          })
      : [];
  }, [data]);

  return (
    <div className="energy-re-calculate">
      <h4>{data?.title || ""}</h4>
      <p className="mb-4">{data?.subtitle || ""}</p>
      <div className="ag-theme-quartz">
        <AgGridReact
          loadingOverlayComponent={isLoading}
          loadingCellRenderer={isLoading}
          rowData={rowData}
          columnDefs={colDefs}
          columnTypes={colTypes}
        />
      </div>
    </div>
  );
}

export default ReCalCulPage;
