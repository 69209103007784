import * as mock from "./module/mock.api";
import baseApi from "./base.api";
import { getToken } from "../storages/userStorage";

if (getToken()) {
  baseApi.setToken(btoa(getToken()));
}

const apis = {
  mock,
};

export default apis;
