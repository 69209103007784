import _ from "lodash";
import { Input, InputNumber, Checkbox, Form } from "antd";
import SelectTypeBien from "components/form/generalites/select-type-bien";
import {
  MappingRenderFieldGeneralites,
  TYPE_FIELD,
  typeBienOptions,
} from "constants/form";
import { MapGeneralites } from "constants/index";
import { cookLabelGeneralites } from "utils/index";
import { ReactComponent as IconBrick } from "assets/images/brick.svg";

const ELEMENT = {
  [TYPE_FIELD.INPUT_TEXT]: Input,
  [TYPE_FIELD.INPUT_NUMBER]: InputNumber,
  [TYPE_FIELD.CHECKBOX]: Checkbox,
};

function FormElement({
  field,
  value: objectValue,
  isQuickView,
}: {
  field: string;
  value: any;
  isQuickView: boolean;
}) {
  if (field === "type_bien") {
    const label = (
      <div className="flex items-center gap-1">
        <span className="order-2 sm:order-1">{MapGeneralites[field]}</span>
        <IconBrick className="w-8 order-1 sm:order-2" />
      </div>
    );
    const optionReadMode: any = _.find(typeBienOptions, {
      value: objectValue.value,
    });

    if (isQuickView) {
      return (
        <Form.Item label={label} name={["generalites", "type_bien", "value"]}>
          <span className="font-bold ant-value">{optionReadMode?.label}</span>
        </Form.Item>
      );
    }
    return (
      <SelectTypeBien
        label={label}
        name={["generalites", "type_bien", "value"]}
      />
    );
  }
  //@ts-ignore
  const Element = ELEMENT[MappingRenderFieldGeneralites[field]];
  if (!Element) {
    return null;
  }

  const readModeValue = !_.isNaN(Number(objectValue.value))
    ? Number(objectValue.value).toLocaleString()
    : objectValue.value;
  const label = cookLabelGeneralites(field, objectValue, isQuickView);
  //@ts-ignore
  return (
    <Form.Item name={["generalites", field, "value"]} label={label}>
      {isQuickView ? (
        <span className="font-bold ant-value">
          {readModeValue} {objectValue?.unit ? objectValue?.unit : ""}
        </span>
      ) : (
        <Element />
      )}
    </Form.Item>
  );
}

export default FormElement;
