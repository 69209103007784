import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import {
  MapLabelType,
  MappingMeasureUnit,
  MapGeneralites,
} from "constants/index";
import { MapIconField } from "constants/icon";
import { MATERIAL_RADIO_OPTIONS } from "constants/form";

dayjs.extend(utc);

export function cookDisplayMockData(
  key: string,
  value: any,
  isQuickView: boolean
) {
  let label = key;
  let classIcon = "w-8 h-8 order-1 sm:order-2";
  //@ts-ignore
  let returnValue = value;

  switch (key) {
    case "type_de_bien":
    case "type_enveloppe":
    case "type_local_adjacent":
      label = MapLabelType[key];
      break;
    // case "exposition":
    //   returnValue = MapGeneralites[value];
    //   break;
    case "isolation":
      returnValue = Boolean(value);
      break;
    case "surface":
    case "epaisseur":
      label = `${label} ${!isQuickView ? `(${value[1]})` : ""}`;
      returnValue = value[0];
      break;
    default:
      break;
  }

  const Icon = MapIconField[key];

  return {
    label: (
      <div className="flex items-center gap-1">
        <span className="order-2 sm:order-1">
          {capitalizeFirstLetter(label)}
        </span>
        {Icon && <Icon className={classIcon} />}
      </div>
    ),
    value: returnValue,
  };
}

export function cookLabelMockData(key: string, isQuickView: boolean) {
  let label = key;
  let classIcon = "w-8 h-8 order-1 sm:order-2";

  switch (key) {
    case "type_de_bien":
    case "type_enveloppe":
    case "type_local_adjacent":
      label = MapLabelType[key];
      break;
    case "surface":
    case "epaisseur":
      label = `${label} ${
        !isQuickView ? `(${MappingMeasureUnit[label]})` : ""
      }`;
      break;
    default:
      break;
  }

  const Icon = MapIconField[key];

  return (
    <div className="flex items-center gap-1">
      <span className="order-2 sm:order-1">{capitalizeFirstLetter(label)}</span>
      {Icon && <Icon className={classIcon} />}
    </div>
  );
}

export function cookLabelGeneralites(
  key: string,
  info: any,
  isQuickView: boolean
) {
  let label = key;
  let classIcon = "w-8 order-1 sm:order-2";

  switch (key) {
    case "alt":
    case "livable_surface_housing":
    case "livable_surface_building":
    case "avg_height_floor":
      label = MapGeneralites[key] + (!isQuickView ? ` (${info?.unit})` : "");
      break;

    default:
      label = MapGeneralites[key];
      break;
  }

  const Icon = MapIconField[key];

  return (
    <div className="flex items-center gap-1">
      <span className="order-2 sm:order-1">{capitalizeFirstLetter(label)}</span>
      {Icon && <Icon className={classIcon} />}
    </div>
  );
}

export function capitalizeFirstLetter(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function materialSelectValeWithRadios(value: string) {
  const foundMaterialRadioOptions = Object.keys(MATERIAL_RADIO_OPTIONS).find(
    (key) => {
      //@ts-ignore
      return !!MATERIAL_RADIO_OPTIONS[key].find((item) => item.value === value);
    }
  );
  return foundMaterialRadioOptions;
}
