import { Button } from "antd";
import { DeleteFilled } from "@ant-design/icons";
import DisplayElement from "components/form/enveloppe/display-element";
import FormElement from "components/form/enveloppe/form-element";

import { MockData } from "services/api/type/mock.type";
import { cookDisplayMockData, cookLabelMockData } from "utils/index";
import { MapGeneralites } from "constants/index";
import { SetOfFields } from "constants/form";

interface Props {
  data: MockData & { isAddNew?: boolean };
  appearance: number;
  field: any;
  isQuickView: boolean;
  onDelete: () => void;
}

function RowData({ data, appearance, field, isQuickView, onDelete }: Props) {
  if (data?.isAddNew) {
    return (
      <div className="row-data">
        <div className="col-title">
          {data?.type_enveloppe && (
            <>
              <p>
                {data?.type_enveloppe} {appearance}
              </p>
              <p>{MapGeneralites[data?.exposition]}</p>
            </>
          )}

          {!isQuickView && (
            <Button
              icon={<DeleteFilled />}
              className="mt-4"
              onClick={onDelete}
            ></Button>
          )}
        </div>
        <div className="col-info">
          {data?.type_enveloppe &&
            Object.keys(SetOfFields[data?.type_enveloppe]).map((key, index) => {
              //@ts-ignore
              const label = cookLabelMockData(key, isQuickView);

              //@ts-ignore
              const value = data[key];
              const fieldName = [field.name, key];
              return (
                <FormElement
                  key={index}
                  type_enveloppe={data?.type_enveloppe}
                  label={label}
                  name={fieldName}
                  renderField={key}
                  valuePropName={key === "isolation" ? "checked" : "value"}
                  isQuickView={isQuickView}
                  value={value}
                  material={data?.materiau}
                />
              );
            })}
        </div>
      </div>
    );
  }

  return (
    <div className="row-data">
      <div className="col-title">
        <p>
          {data.type_enveloppe} {appearance}
        </p>
        <p>{MapGeneralites[data.exposition]}</p>

        {!isQuickView && (
          <Button
            icon={<DeleteFilled />}
            className="mt-4"
            onClick={onDelete}
          ></Button>
        )}
      </div>
      <div className="col-info">
        {Object.keys(data)
          .filter((key) => !["type_enveloppe", "appearance"].includes(key))
          .map((key, index) => {
            //@ts-ignore
            const { label } = cookDisplayMockData(key, data[key], isQuickView);
            const fieldName = [field.name, key];
            if (["epaisseur", "surface"].includes(key)) {
              fieldName[2] = 0;
            }

            return (
              <DisplayElement
                label={label}
                name={fieldName}
                key={index}
                renderField={key}
                isQuickView={isQuickView}
                data={data}
                fieldKey={key}
              />
            );
          })}
      </div>
    </div>
  );
}

export default RowData;
