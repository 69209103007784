import Cover2 from "assets/images/cover-2.jpg";
import Cover3 from "assets/images/cover-3.jpg";
import Cover4 from "assets/images/cover-4.jpg";
import Cover5 from "assets/images/cover-5.jpg";
import Cover6 from "assets/images/cover-6.jpg";
import Cover7 from "assets/images/cover-7.jpg";

export const getLandingLocale = () => {
  return {
    top: {
      all: {
        title: "Exploitez votre DPE de façon fiable et actionnable",
        description:
          "Contrairement à une opinion répandue, le Lorem Ipsum n'est pas simplement du texte aléatoire. Il trouve ses racines dans une oeuvre de la littérature latine classique datant de 45 av. J.-C., le rendant vieux de 2000 ans. Un professeur d",
        bg_images_xl: [],
        bg_images_m: [],
      },
      acheter: {
        title: "Pour votre projet d'acquisition, exploitez votre DPE",
        description:
          "Contrairement à une opinion répandue, le Lorem Ipsum n'est pas simplement du texte aléatoire. Il trouve ses racines dans une oeuvre de la littérature latine classique datant de 45 av. J.-C., le rendant vieux de 2000 ans. Un professeur d",
        bg_images_xl: [],
        bg_images_m: [],
      },
      vendre: {
        title: "Pour votre projet de vente, exploitez votre DPE",
        description:
          "Contrairement à une opinion répandue, le Lorem Ipsum n'est pas simplement du texte aléatoire. Il trouve ses racines dans une oeuvre de la littérature latine classique datant de 45 av. J.-C., le rendant vieux de 2000 ans. Un professeur d",
        bg_images_xl: [],
        bg_images_m: [],
      },
      renover: {
        title: "Pour votre projet de rénovation, exploitez votre DPE",
        description:
          "Contrairement à une opinion répandue, le Lorem Ipsum n'est pas simplement du texte aléatoire. Il trouve ses racines dans une oeuvre de la littérature latine classique datant de 45 av. J.-C., le rendant vieux de 2000 ans. Un professeur d",
        bg_images_xl: [],
        bg_images_m: [],
      },
    },
    personae: {
      acheter: {
        title: "Vous êtes acheteur et voulez cela",
        description:
          "Maîtrisez la vraie valeur de votre bien, sans surprise pour la performance énergétique",
        bg_image: Cover2,
      },
      vendre: {
        title: "Vous êtes proriétaire et voulez ceci",
        description:
          "Maximisez le prix de vente de votre bien en sachant proposer un DPE lisible et factuel",
        bg_image: Cover3,
      },
      renover: {
        title: "Faites des travaux",
        description:
          "Décidez des travaux à réaliser en tenant compte de leur impact sur la performance énergétique et leur coût",
        bg_image: Cover4,
      },
    },
    banner_2: {
      all: {
        title: "Découvrez les 3 étapes pour exploiter votre DPE",
        description:
          "Contrairement à une opinion répandue, le Lorem Ipsum n'est pas simplement du texte aléatoire. Il trouve ses racines dans une oeuvre de la littérature latine classique datant de 45 av. J.-C., le rendant vieux de 2000 ans. Un professeur d",
        cta: "Découvrir les 3 étapes",
        bg_image: Cover5,
      },
      vendre: {
        title: "Découvrez les 3 étapes pour exploiter votre DPE",
        description:
          "Contrairement à une opinion répandue, le Lorem Ipsum n'est pas simplement du texte aléatoire. Il trouve ses racines dans une oeuvre de la littérature latine classique datant de 45 av. J.-C., le rendant vieux de 2000 ans. Un professeur d",
        cta: "Découvrir les 3 étapes",
        bg_image: Cover6,
      },
      acheter: {
        title: "Découvrez les 3 étapes pour exploiter votre DPE",
        description:
          "Contrairement à une opinion répandue, le Lorem Ipsum n'est pas simplement du texte aléatoire. Il trouve ses racines dans une oeuvre de la littérature latine classique datant de 45 av. J.-C., le rendant vieux de 2000 ans. Un professeur d",
        cta: "Découvrir les 3 étapes",
        bg_image: Cover7,
      },
      renover: {
        title: "Découvrez les 3 étapes pour exploiter votre DPE",
        description:
          "Contrairement à une opinion répandue, le Lorem Ipsum n'est pas simplement du texte aléatoire. Il trouve ses racines dans une oeuvre de la littérature latine classique datant de 45 av. J.-C., le rendant vieux de 2000 ans. Un professeur d",
        cta: "Découvrir les 3 étapes",
        bg_image: Cover3,
      },
    },
  };
};
