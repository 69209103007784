import axios from "axios";
import type { AxiosInstance, Method } from "axios";
import get from "lodash/get";
import isNil from "lodash/isNil";

const UPLOAD_HEADER = {
  accept: "application/json",
  "Accept-Language": "en-US,en;q=0.8",
  "Content-Type": "multipart/form-data",
};

class BaseApi {
  client: AxiosInstance;
  clearStore: any;

  constructor(opts = {}) {
    Object.assign(this, opts);

    const baseURL =
      process.env.REACT_APP_API_URL || "http://localhost:5000/api/v1";

    this.client = axios.create({
      timeout: 120000,
    });

    if (baseURL) {
      this.client.defaults.baseURL = baseURL;
    }

    this.client.interceptors.request.use(
      function (config) {
        // Do something before request is sent
        if (
          !config.url?.includes("api-dev.ledpe.fr") &&
          !config.url?.includes("api-d.ledpe.fr")
        ) {
          delete config.headers["Authorization"];
        }

        return config;
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    this.client.interceptors.response.use(
      function (response: any) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function (error: any) {
        // const token = getToken();
        // if (error.response?.status === 401 && token) {
        //   clearToken();
        //   history.replace(PAGES.login);
        // }
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
      }
    );
  }

  static getInstance() {
    return new BaseApi();
  }

  static buildOptions(method: string, url: string, params = {}, headers = {}) {
    return {
      method,
      url,
      params,
      headers,
    };
  }

  setBaseURL(baseURL: string) {
    this.client.defaults.baseURL = baseURL;
  }

  setClearCallback(clearStore: any) {
    this.clearStore = clearStore;
  }

  clearSession() {
    if (this.clearStore) {
      this.clearStore();
    }
  }

  setDefaultHeader(key: string, value: any) {
    this.client.defaults.headers[key] = value;
  }

  setToken(token: string) {
    if (!token) {
      return;
    }
    this.setDefaultHeader("Authorization", "Bearer " + token);
  }

  setDefaultHeaders(headers: any) {
    this.client.defaults.headers = {
      ...this.client.defaults.headers,
      ...headers,
    };
  }

  removeHeaderAttr(attribute: any) {
    delete this.client.defaults.headers[attribute];
  }

  async request(opts: any) {
    const requestOpts = BaseApi.preRequest(opts);

    return this.client(requestOpts)
      .then((response: any) => BaseApi.preResponse(response))
      .catch((error: any) => {
        return BaseApi.preResponse(error);
      });
  }

  async buildRequest(
    method: Method | string,
    uri: string,
    payload?: any,
    params?: any
  ) {
    switch (method.toLowerCase()) {
      case "get":
        return this.get(uri, params);
      case "post":
        return this.post(uri, payload);
      case "put":
        return this.put(uri, payload);
      case "delete":
        return this.delete(uri, payload);
    }
    const opts = BaseApi.buildOptions(method, uri, params);
    return this.request(opts);
  }

  async delete(uri: any, params?: any, headers?: any) {
    const opts = BaseApi.buildOptions("delete", uri, params, headers);

    return this.request(opts);
  }

  async get(uri: any, params?: any, headers?: any) {
    const opts = BaseApi.buildOptions("get", uri, params, headers);
    return this.request(opts);
  }

  async post(uri: string, payload?: any, params?: any, headers?: any) {
    const opts = BaseApi.buildOptions("post", uri, params, headers) as any;

    opts.data = payload;

    return this.request(opts);
  }

  async putFormData(uri: string, payload?: any, params?: any) {
    const opts = BaseApi.buildOptions("put", uri, params, UPLOAD_HEADER) as any;

    opts.data = payload;

    return this.request(opts);
  }

  async postFormData(uri: string, payload?: any, params?: any) {
    const opts = BaseApi.buildOptions(
      "post",
      uri,
      params,
      UPLOAD_HEADER
    ) as any;

    opts.data = payload;

    return this.request(opts);
  }

  async put(uri: string, payload?: any, params?: any, headers?: any) {
    const opts = BaseApi.buildOptions("put", uri, params, headers) as any;

    opts.data = payload;

    return this.request(opts);
  }

  async patch(uri: string, payload?: any, params?: any, headers?: any) {
    const opts = BaseApi.buildOptions("patch", uri, params, headers) as any;

    opts.data = payload;

    return this.request(opts);
  }

  async upload(uri: string, file: any) {
    return this.client
      .post(uri, file, { headers: UPLOAD_HEADER })
      .then((response: any) => BaseApi.preResponse(response))
      .catch((error: any) => BaseApi.preResponse(error));
  }

  static preRequest(opts: any) {
    return {
      ...opts,
    };
  }

  static processError(rawResponse: any) {
    const {
      response: { data },
    } = rawResponse;
    const { code, msg } = data;

    if (code) {
      const error = {
        code,
        message: msg,
      };
      throw error;
    }
    throw msg;
  }

  static preResponse(rawResponse: any) {
    if (rawResponse.response || rawResponse.code) {
      BaseApi.processError(rawResponse);
    }

    const status =
      get(rawResponse, ["status"]) || get(rawResponse, ["response", "status"]);
    let data;
    let error;

    switch (status) {
      case 200:
      case 201:
      case 204:
        data = get(rawResponse, ["data", "data"]) || get(rawResponse, ["data"]);
        const besideData = get(rawResponse, ["data"]);
        if (besideData && besideData.code && besideData.message) {
          data = {
            data: data,
            code: besideData.code,
            message: besideData.message,
          };
        }
        break;
      case 400:
        error = "400 Bad Request Error";
        break;
      case 401:
        this.clearSession();
        error = "401 Unauthorize";
        break;
      case 403:
        error = "403 Forbidden";
        break;
      case 404:
        error = "404 Not Found";
        break;
      default:
        error = "Unknown Error";
    }

    if (isNil(error)) return data;

    // eslint-disable-next-line no-console
    console.error(rawResponse);
    throw error;
  }
  static clearSession() {
    throw new Error("Method not implemented.");
  }
}

export default BaseApi.getInstance();
