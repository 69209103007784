import PAGES from "routes/constants";
import Personas1 from "assets/images/personas-1.jpg";
import Personas2 from "assets/images/sample-1.jpg";
import Personas3 from "assets/images/sample-2.jpg";

export const getVotreItems = (landingLocale: any) => {
  return [
    {
      label: landingLocale?.acheter?.title || "",
      // labelHtml: (
      //   <p>
      //     A. Vous êtes <b>acheteur</b> et voulez cela
      //   </p>
      // ),
      value: "A",
      url: PAGES.acheter,
      desc: landingLocale?.acheter?.description || "",
      thumbnail: Personas1,
    },
    {
      label: landingLocale?.vendre?.title || "",
      // labelHtml: (
      //   <p>
      //     B. Vous êtes <b>proriétaire</b> et voulez ceci
      //   </p>
      // ),
      value: "B",
      url: PAGES.vendre,
      desc: landingLocale?.vendre?.description || "",
      thumbnail: Personas2,
    },
    {
      label: landingLocale?.renover?.title || "",
      // labelHtml: (
      //   <p>
      //     C. faites des <b>travaux</b>
      //   </p>
      // ),
      value: "C",
      url: PAGES.renover,
      desc: landingLocale?.renover?.description || "",
      thumbnail: Personas3,
    },
  ];
};
