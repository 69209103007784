import _ from "lodash";
import { Input, InputNumber, Checkbox, Form } from "antd";
import {
  MATERIAL_RADIO_OPTIONS,
  MappingRenderField,
  TYPE_FIELD,
} from "constants/form";
import { MapValue } from "constants/index";
import SelectMaterial from "./select-material";
import SelectThickness from "./select-thickness";
import SelectExposition from "components/form/enveloppe/select-exposition";

const ELEMENT = {
  [TYPE_FIELD.INPUT_TEXT]: Input,
  [TYPE_FIELD.INPUT_NUMBER]: InputNumber,
  [TYPE_FIELD.CHECKBOX]: Checkbox,
};

function DisplayElement(props: any) {
  const { renderField, isQuickView, data, fieldKey, ...rest } = props;

  //@ts-ignore
  const Element = ELEMENT[MappingRenderField[renderField]];
  if (!Element) {
    return null;
  }

  let otherProps = {};
  if (renderField === "isolation") {
    otherProps = {
      className: "isolation",
      labelCol: {
        order: 2,
        span: 12,
        md: {
          span: 12,
        },
        sm: {
          order: 1,
        },
      },
      wrapperCol: {
        order: 1,
        span: 12,
        md: {
          span: 12,
        },
        sm: {
          order: 2,
        },
      },
    };
  }

  let value = _.isArray(data[fieldKey]) ? data[fieldKey][0] : data[fieldKey];
  value = !_.isNaN(Number(value)) ? Number(value).toLocaleString() : value;
  const unit = _.isArray(data[fieldKey]) ? data[fieldKey][1] : "";

  if (isQuickView) {
    // combine select value + radio value to show
    if (renderField === "materiau") {
      const materialSelect = Object.keys(MATERIAL_RADIO_OPTIONS).find((key) => {
        return MATERIAL_RADIO_OPTIONS[key].find((item: any) => {
          return item.value === value;
        });
      });

      if (materialSelect) {
        return (
          <Form.Item label={rest.label} name={rest.name}>
            <span className="font-bold ant-value">
              {materialSelect} {MapValue[value] || value}
            </span>
          </Form.Item>
        );
      }
    }

    if (renderField === "exposition") {
      return null;
    }

    return (
      <Form.Item label={rest.label} name={rest.name}>
        <span className="font-bold ant-value">
          {MapValue[value] || value} {MapValue[value] || value ? unit : ""}
        </span>
      </Form.Item>
    );
  }

  if (renderField === "exposition") {
    return (
      <div>
        <SelectExposition name={rest.name} label={rest.label} />
      </div>
    );
  }

  if (renderField === "materiau") {
    return (
      <Form.Item {...rest} {...otherProps}>
        <SelectMaterial />
      </Form.Item>
    );
  }

  if (renderField === "epaisseur") {
    return (
      <Form.Item {...rest} {...otherProps}>
        <SelectThickness material={data["materiau"]} />
      </Form.Item>
    );
  }

  return (
    <Form.Item
      {...rest}
      {...otherProps}
      valuePropName={fieldKey === "isolation" ? "checked" : "value"}
    >
      <Element />
    </Form.Item>
  );
}

export default DisplayElement;
